import { combineReducers } from 'redux';

import address from './address';
import application from './application';
import form from './form';

const rootReducer = combineReducers({
  address,
  application,
  form,
});

export default rootReducer;
