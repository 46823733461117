import React, { useState, useEffect, useCallback } from 'react';
import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Axios from 'axios';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import * as formActions from '../../../Action/form';
import Config from '../../../Config';

import './button.css';

const PaymentButtons = ({
  disabled,
  form,
}) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const history = useHistory();

  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { amount } = form;

  const paymentComplete = (transactionID) => {
    history.push({ pathname: '/success', state: { transactionID, amount } });
  };

  const onCancel = () => {
    window.location.reload(true);
  };

  const handlePaymentMethodReceived = async (paymentMethod) => {
    const executeResponse = await Axios({
      method: 'post',
      url: Config.api.stripe,
      data: {
        payment_method_id: paymentMethod.paymentMethod.id,
        amount: Math.floor(form.amount * 100),
        payment_button: true,
        token,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: () => true,
    });

    if (executeResponse.status !== 201) {
      const paymentButtonErrorMessage = typeof executeResponse.data !== 'undefined'
        ? executeResponse.data.message
        : executeResponse.message;

      paymentMethod.complete('success');

      if (paymentButtonErrorMessage.indexOf('The provided PaymentMethod') !== -1) {
        Sentry.captureException(new Error(paymentButtonErrorMessage));

        history.push({ pathname: '/', state: { paymentButtonError: 'An error has occurred due to your payment being cancelled mid-payment. Please try again, you have not been charged.' } });

        return null;
      }

      setRefreshReCaptcha(r => !r);
      Sentry.captureException(new Error(paymentButtonErrorMessage));

      history.push({ pathname: '/', state: { paymentButtonError: paymentButtonErrorMessage } });

      return null;
    }

    paymentMethod.complete('success');
    paymentComplete(executeResponse.data.transaction_id, form.amount);

    return null;
  };

  useEffect(() => {
    if (stripe) {
      setPaymentRequest(null);

      if (!disabled) {
        const pr = stripe.paymentRequest({
          country: 'IE',
          currency: 'eur',
          total: {
            label: 'Donation',
            amount: Math.floor(amount * 100),
          },
          requestPayerName: true,
          requestPayerEmail: true,
          disableWallets: ['browserCard'],
        });

        // Check the availability of the Payment Request API first.
        pr.canMakePayment().then((result) => {
          if (result) {
            setPaymentRequest(pr);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amount,
    stripe,
    disabled,
  ]);

  const onVerify = useCallback((rToken) => {
    setToken(rToken);
  }, []);

  if (paymentRequest && disabled === false) {
    return (
      <>
        {
          typeof process.env.REACT_APP_RECAPTCHA_SITEKEY !== 'undefined' && (
            <GoogleReCaptcha
              onVerify={onVerify}
              refreshReCaptcha={refreshReCaptcha}
            />
          )
        }

        <PaymentRequestButtonElement
          className="stripe-pay-buttons"
          style={{ width: '100%', paddingTop: '1.5em' }}
          onClick={() => {
            paymentRequest.on('paymentmethod', handlePaymentMethodReceived);
            paymentRequest.on('cancel', onCancel);
            paymentRequest.on('abort', onCancel);
          }}
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                type: 'donate',
                // One of 'default', 'book', 'buy', or 'donate'
                // Defaults to 'default'

                theme: 'dark',
                // One of 'dark', 'light', or 'light-outline'
                // Defaults to 'dark'

                height: '79px',
                // Defaults to '40px'. The width is always '100%'.
              },
            },
          }}
        />
      </>
    );
  }

  return <div />;
};

export default connect(
  props => props,
  dispatch => bindActionCreators({ ...formActions }, dispatch),
)(PaymentButtons);
