import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Link, useTheme } from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import * as formActions from '../../Action/form';
import signature from '../../assets/patrick-signature.png';

const NewDonation = withStyles(theme => ({
  root: {
    color: theme.button.color,
    fontSize: '1.6rem',
    padding: theme.spacing(2),
    backgroundColor: theme.button.background,
    boxShadow: '2px 2px 0px #a13134',
    transform: 'translate(0, 0)',
    '&:focus, &:hover, &.selected': {
      backgroundColor: theme.button.backgroundHover,
      boxShadow: 'none',
      transform: 'translate(1px, 1px)',
    },
    width: '100%',
    textTransform: 'inherit',
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    margin: '0 0 2rem',
  },
  wrapper: {
    maxWidth: '500px',
    width: '100%',
    margin: '2rem auto',
  },
  signatureImage: {
    width: '200px',
    margin: '1rem 0',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
  number: {
    backgroundColor: '#eeecec',
    padding: '0.5rem',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default connect(
  props => props,
  dispatch => bindActionCreators({ ...formActions }, dispatch),
)(({
  application: { text },
  form,
  address,
  resetAddressState,
  resetFormState,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const amount = typeof location.state !== 'undefined' && typeof location.state.amount !== 'undefined' ? location.state.amount : form.amount;
  const transactionID = typeof location.state !== 'undefined' && typeof location.state.transactionID !== 'undefined' ? location.state.transactionID : form.transaction_id;

  const handleNewDonation = () => {
    resetAddressState();
    resetFormState();
    history.push({ pathname: address.agent });
  };

  return (
    <>
      {address.agent ? (
        <div className={classes.wrapper}>
          <Typography
            variant="h4"
            className={classes.title}
            component="h2"
            align="center"
          >
            Donation complete
          </Typography>
          <Typography component="p" gutterBottom>
            Your donation reference number is:
          </Typography>
          <Typography
            component="p"
            className={classes.number}
            gutterBottom
            align="center"
          >
            <b>{form.transaction_id}</b>
          </Typography>
          <Typography
            variant="h4"
            className={classes.title}
            component="h2"
            align="center"
          >
            <NewDonation
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleNewDonation()}
            >
              Make a new donation
            </NewDonation>
          </Typography>
        </div>
      ) : (
        <div className={classes.wrapper}>
          <Typography
            variant="h4"
            className={classes.title}
            component="h2"
            align="center"
          >
            { text.Success.title }
          </Typography>
          { theme.name === 'toyshowTheme' ? (
            <Typography component="p" gutterBottom>
              Your generous donation of
              {' '}
              <b>
                €
                {amount}
              </b>
              {' '}
              will help thousands of children right across the island of Ireland this year.
            </Typography>
          ) : (
            <Typography component="p" gutterBottom>
              { text.Success.you_donated }
              {' '}
              <b>
                €
                {amount}
              </b>
              .
            </Typography>
          )}

          <Typography component="p" gutterBottom>
            { text.Success.donation_ref }
          </Typography>
          <Typography
            component="p"
            className={classes.number}
            gutterBottom
            align="center"
          >
            <b>{transactionID}</b>
          </Typography>
          { theme.name === 'toyshowTheme' ? (
            <>
              <Typography component="p" gutterBottom>
                Thanks for watching and THANK YOU for giving.
              </Typography>
              <Typography component="p">
                A Very Happy Christmas from Patrick and all the RTÉ Toy Show Appeal team.
                <br />
                <img
                  src={signature}
                  alt="Patrick signature"
                  className={classes.signatureImage}
                />
              </Typography>
            </>
          ) : (
            <>
              <Typography component="p" gutterBottom>
                { text.Success.funds_text }
                {' '}
                <Link
                  color="inherit"
                  href="mailto:info@foundation.ie"
                  target="_blank"
                  rel="noopener"
                >
                  info@foundation.ie
                </Link>
                .
              </Typography>
            </>
          )}
        </div>
      )}
    </>
  );
});
