import { Component } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * ScrollToTop class
 */
class ScrollToTop extends Component {
  /**
   * ScrollToTop componentDidUpdate
   */
  componentDidUpdate() {
    const elmnt = document.getElementById('root');
    try {
      elmnt.scrollIntoView(true);
    } catch (err) {
      if (err instanceof TypeError) {
        window.scrollTo(0, 0);
      } else {
        throw err;
      }
    }
  }

  /**
   * ScrollToTop render
   */
  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
