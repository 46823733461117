import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import * as formActions from '../../../Action/form';

const useStyles = makeStyles(theme => ({
  input: {
    margin: theme.spacing(3, 0),
    width: '66%',
    input: {
      height: '64px',
    },
  },
}));

function preventTextEntry(data) {
  const numberEntry = data;
  const decimalPlace = numberEntry.indexOf('.');

  return decimalPlace > -1
    ? numberEntry.substr(0, decimalPlace) + numberEntry.substr(decimalPlace, 3)
    : numberEntry;
}

/**
 * Function to change amount
 * @param event
 */

const AmountInput = ({
  application: { text },
  form: { amount, interacted },
  updateAmount,
}) => {
  const classes = useStyles();

  const changeAmount = event => {
    event.preventDefault();
    const result = preventTextEntry(event.target.value);

    updateAmount(result);
  };

  return (
    <div className={classes.input}>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="field-input--amount">{text.DonationForm.amount_input_holder}</InputLabel>
        <OutlinedInput
          type="number"
          id="field-input--amount"
          value={amount}
          onChange={e => {
            changeAmount(e);
          }}
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          labelWidth={100}
          name="text"
          placeholder={text.DonationForm.amount_placeholder}
          pattern="[^[1-9]+$]"
          min="2"
          max="25000"
          required
        />
        {(amount < 2 || amount > 25000 || amount === '') && interacted && (
          <FormHelperText id="field-input--amount-text">
            {text.DonationForm.amount_error}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default connect(
  props => props,
  dispatch => bindActionCreators({ ...formActions }, dispatch),
)(AmountInput);
