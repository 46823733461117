/* eslint-disable react/no-danger */
import React from 'react';
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Elements } from '@stripe/react-stripe-js';
import { Typography, Link, useTheme } from '@material-ui/core';
import {
  makeStyles,
} from '@material-ui/core/styles';

import ScrollToTop from './Component/ScrollToTop';
import Store from './Store';
import DonationForm from './View/DonationForm';
import GiftaidForm from './View/GiftaidForm';
import AddressForm from './View/AddressForm';
import PaymentForm from './View/PaymentForm';
import Success from './View/Success';
import Sorry from './View/Sorry';
import Terms from './View/Terms';
import RteHeader from './View/RteHeader';
import Agents from './View/Agents';

import RTEFooter from './rte/footer.html';
import RTEPrivacy from './rte/privacy.html';
import Config from './Config';
import HeightPusher from './Component/HeightPusher';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(0, 'auto'),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 'auto'),
    },
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '700px',
    width: '100%',
    backgroundColor: 'white',
  },
  ...theme.images.backgroundImage && {
    layout: {
      backgroundImage: theme.images.backgroundImage,
      backgroundAttachment: 'fixed',
    },
  },
  logo: {
    margin: '1rem auto 0',
    maxWidth: '400px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '230px',
    },
  },
  subLogo: {
    margin: '1rem auto 0',
    maxWidth: '250px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '200px',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  preFooter: {
    width: '100%',
    backgroundColor: '#eeecec',
    padding: theme.spacing(1),
  },
  preFooterWrapper: {
    maxWidth: '1160px',
    margin: theme.spacing(2, 'auto'),
    padding: theme.spacing(3),
  },
  copyRight: {
    padding: theme.spacing(2),
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
  },
}));

const stripePromise = loadStripe(Config.payments.stripe.public);

const FooterText = () => {
  const theme = useTheme();

  if (theme.name === 'toyshowTheme') {
    return (
      <>
        <Typography component="p" gutterBottom>
          Community Foundation Ireland is a registered charity
          (Registered Charity Number (RCN): 20044886) with registered
          address: 30 Merrion Square North, Dublin 2, D02 VE40. Your donation and
          other data are collected directly by Community Foundation Ireland.
          RTÉ does not process this transaction and does
          not collect, or control, any data regarding your donation. You
          can contact Community Foundation Ireland at
          {' '}
          <Link
            color="inherit"
            href="mailto:info@foundation.ie"
            target="_blank"
            rel="noopener"
          >
            info@foundation.ie
          </Link>
          .
        </Typography>
        <Typography component="p" gutterBottom>
          Stripe is Community Foundation Ireland&apos;s partner for
          online payments processing. Stripe has waived all processing fees
          on your donation to the RTÉ Toy Show Appeal. Network fees will however
          continue to apply. Your details are held in accordance with Community
          Foundation Ireland’s
          {' '}
          <Link
            color="inherit"
            href="https://www.communityfoundation.ie/privacy-cookie-policy/"
            target="_blank"
            rel="noopener"
          >
            Privacy Policy
          </Link>
          .
        </Typography>
        <Typography component="p">
          The Toy Show Appeal donations site uses only Strictly
          Necessary Cookies, as outlined in the RTÉ
          {' '}
          <Link
            color="inherit"
            href="https://about.rte.ie/reports-and-policies/cookie-policy/"
            target="_blank"
            rel="noopener"
          >
            Cookie policy
          </Link>
          .
        </Typography>
      </>
    );
  }
  return (
    <>
      <Typography component="p" gutterBottom>
        Community Foundation Ireland is a registered charity
        (Registered Charity Number (RCN): 20044886) with registered
        address: 30 Merrion Square North, Dublin 2, D02 VE40. Your donation and
        other data are collected directly by Community Foundation Ireland.
        You can contact Community Foundation Ireland at
        {' '}
        <Link
          color="inherit"
          href="mailto:info@foundation.ie"
          target="_blank"
          rel="noopener"
        >
          info@foundation.ie
        </Link>
        .
      </Typography>
      <Typography component="p" gutterBottom>
        Your details are held in accordance with Community
        Foundation Ireland’s
        {' '}
        <Link
          color="inherit"
          href="https://www.communityfoundation.ie/privacy-cookie-policy/"
          target="_blank"
          rel="noopener"
        >
          Privacy Policy
        </Link>
        .
      </Typography>
      <Typography component="p">
        The appeal donations site uses only Strictly Necessary Cookies.
      </Typography>
    </>
  );
};

function Main() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className="App" id="donation-form">
      <Provider store={Store}>
        <Elements stripe={stripePromise}>
          <CssBaseline />
          <Grid
            item
            xs={12}
            component={Paper}
            className={classes.layout}
            elevation={0}
            square
          >
            {
                ((typeof process.env.REACT_APP_HEADER_DISABLED === 'undefined' || process.env.REACT_APP_HEADER_DISABLED !== 'yes') && theme.name !== 'ukraineTheme') && (
                  <RteHeader />
                )
              }
            <Grid
              container
              role="main"
              component="main"
              className={classes.paper}
            >
              {process.env.REACT_APP_THEME !== 'ukraineTheme' && (
                <>
                  <div className={classes.logo}>
                    <img
                      src={theme.images.titleImage.src}
                      alt={theme.images.titleImage.alt}
                      className={classes.titleImage}
                    />
                  </div>
                </>
              )}
              <Router basename={process.env.PUBLIC_URL}>
                <>
                  <ScrollToTop />
                  <HeightPusher />
                  <Switch>
                    <Route exact path="/" component={DonationForm} />
                    <Route exact path="/giftaid" component={GiftaidForm} />
                    <Route exact path="/address" component={AddressForm} />
                    <Route exact path="/payment" component={PaymentForm} />
                    <Route exact path="/success" component={Success} />
                    <Route exact path="/sorry" component={Sorry} />
                    <Route exact path="/terms" component={Terms} />
                    <Route exact path="/agent/:username" component={Agents} />
                    <Redirect push to="/" />
                  </Switch>
                </>
              </Router>
            </Grid>

            { theme.name === 'toyshowTheme' && (
            <div className={classes.preFooter}>
              <div className={classes.preFooterWrapper}>
                <FooterText />
              </div>
            </div>
            )}

            {
                ((typeof process.env.REACT_APP_FOOTER_DISABLED === 'undefined' || process.env.REACT_APP_FOOTER_DISABLED !== 'yes') && theme.name !== 'ukraineTheme') && (
                  <div dangerouslySetInnerHTML={{ __html: RTEFooter }} />
                )
              }
            <div dangerouslySetInnerHTML={{ __html: RTEPrivacy }} />
          </Grid>
        </Elements>
      </Provider>
    </div>
  );
}

export default Main;
