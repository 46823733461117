import React from 'react';
import {
  ThemeProvider,
} from '@material-ui/core/styles';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Main from './Main';
import getTheme from './Theme/base';
import Head from './Head';

function App() {
  const theme = getTheme(process.env.REACT_APP_THEME ?? 'toyshowTheme');

  const container = (
    <ThemeProvider theme={theme}>
      <Head />
      <Main />
    </ThemeProvider>
  );

  if (typeof process.env.REACT_APP_RECAPTCHA_SITEKEY !== 'undefined') {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY}>
        { container }
      </GoogleReCaptchaProvider>
    );
  }

  return container;
}

export default App;
