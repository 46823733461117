import { SET_LANGUAGE, SET_TEXT } from '../Action/application';
import EnglishTranslations from '../Language/en.json';

export const LANGUAGES = {
  EN: 'EN',
  GA: 'GA',
};

const defaultState = {
  language: LANGUAGES.EN,
  text: EnglishTranslations,
};

/**
 * Application Reducer
 * @param state
 * @param action
 * @return {*}
 */
export default function application(state = defaultState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_TEXT:
      return {
        ...state,
        text: action.payload,
      };
    default:
      return state;
  }
}
