import {
  createTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import ibmSans from '../assets/fonts/ibm-plex-sans-v8-latin-regular.woff2';
import bgImage from '../assets/hearts-bg-show.png';
import titleImage from '../assets/toy-show-2023.png';

const ibmSansFont = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('IBM Plex Sans'),
    local('ibm-plex-sans-v8-latin-regular'),
    url(${ibmSans}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export default responsiveFontSizes(createTheme({
  name: 'toyshowTheme',
  body: {
    fontSize: '16px',
  },
  typography: {
    fontFamily: 'IBM Plex Sans, sans-serif',
  },
  button: {
    background: '#fdb400',
    backgroundHover: '#d69800',
    color: '#fff',
    colorHover: '#fff',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [ibmSansFont],
      },
    },
    MuiLink: {
      root: {
        color: '#005ab4',
      },
    },
  },
  images: {
    backgroundImage: `url(${bgImage})`,
    titleImage: {
      src: titleImage,
      alt: 'Late Late Toy Show',
    },
  },
}));
