import { all } from 'redux-saga/effects';

import FormSagas from './Form';
import ApplicationSagas from './Application';

/**
 * Saga Reducer Hooks
 */
export default function* sagas() {
  yield all([
    FormSagas(),
    ApplicationSagas(),
  ]);
}
