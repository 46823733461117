import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import throttle from 'lodash.throttle';
import createSagaMiddleware from 'redux-saga';

import { saveState, loadState } from './PersistentState';
import { triggerLoad } from '../Action/application';

// import root reducer
import rootReducer from '../Reducer/index';
import sagas from '../Saga';

// load state from sessionStorage
const persistedState = loadState();

// create object for default state
// create object for default state
const defaultState = typeof persistedState !== 'undefined'
  ? {
    address: persistedState.address,
    application: persistedState.application,
    form: persistedState.form,
  }
  : {};

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// create store which takes 3 arguments: rootReducer, defaultState and middlewares
// default state will override the initial state specified by the reducers
const store = createStore(
  rootReducer,
  defaultState,
  applyMiddleware(thunkMiddleware, sagaMiddleware),
);

sagaMiddleware.run(sagas);

// called every time the storage state changes
// use throttle so we're only writing to sessionStorage once a second
store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000),
);

store.dispatch(triggerLoad());

export default store;
