import { takeLatest, put, select } from 'redux-saga/effects';
import { LOAD, SET_LANGUAGE, SET_TEXT } from '../../Action/application';
import { getApplication } from '../Selectors';

import EnglishTranslations from '../../Language/en.json';
import IrishTranslations from '../../Language/ga.json';

const translations = {
  en: EnglishTranslations,
  ga: IrishTranslations,
};

/**
 * Set the language
 */
function* setLanguage() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let language = urlParams.get('lang');

  if (typeof language === 'undefined' || language === null) {
    language = 'en';
  }

  yield put({ type: SET_LANGUAGE, payload: language.toLowerCase() });
}

/**
 * Set text
*/
function* onLanguageChange() {
  const { language } = yield select(getApplication);

  let text = EnglishTranslations;

  if (typeof translations[language] !== 'undefined') {
    text = translations[language];
  }

  yield put({ type: SET_TEXT, payload: text });
}

/**
 * Register application sagas
 */
export default function* sagas() {
  yield takeLatest(LOAD, setLanguage);
  yield takeLatest(SET_LANGUAGE, onLanguageChange);
}
