import toyshowTheme from './toyshow';
import ukraineTheme from './ukraine';

const themeMap = {
  toyshowTheme,
  ukraineTheme,
};

export default function getTheme(theme) {
  return themeMap[theme]
    ? themeMap[theme]
    : toyshowTheme;
}
