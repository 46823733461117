import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';

import * as formActions from '../../Action/form';

const ContinueButton = withStyles(theme => ({
  root: {
    color: theme.button.color,
    fontSize: '1.6rem',
    padding: theme.spacing(2),
    backgroundColor: theme.button.background,
    boxShadow: '2px 2px 0px #a13134',
    transform: 'translate(0, 0)',
    '&:focus, &:hover, &.selected': {
      backgroundColor: theme.button.backgroundHover,
      boxShadow: 'none',
      transform: 'translate(1px, 1px)',
    },
    width: '100%',
    textTransform: 'inherit',
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    margin: '0 0 1rem',
  },
  back: {
    textTransform: 'capitalize',
    margin: '2rem 0 4rem',
  },
  form: {
    margin: '2rem 0',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    maxWidth: '500px',
    width: '100%',
    margin: '2rem auto 0',
  },
}));

const DonationForm = ({
  application: { text },
  address,
  form: { giftaid, step },
  updateGiftaid,
  updateStep,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const goBack = event => {
    event.preventDefault();
    if (address.agent) {
      updateStep(2);
      history.push({ pathname: address.agent });
    } else {
      updateStep(0);
      history.push({ pathname: '/' });
    }
  };

  if (step < 1) {
    history.push({ pathname: '/' });
  }

  const onSubmit = event => {
    event.preventDefault();

    if (address.agent) {
      updateStep(3);
      history.push({ pathname: '/payment' });
    } else {
      updateStep(2);
      history.push({ pathname: '/address' });
    }
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Typography
          className={classes.title}
          variant="h4"
          component="h2"
          align="center"
        >
          {text.Giftaid.title}
        </Typography>
        <Typography component="p">
          { theme.name === 'toyshowTheme' ? (
            <>
              Did you know you can grow your donation by 44.9% simply by signing a
              form? If you are donating €250 or more and your donation is eligible
              we can apply for a tax refund which will be donated to the RTÉ Toy
              Show Appeal at Community Foundation Ireland.

            </>
          ) : (
            <>
              {text.Giftaid.sub}
            </>
          )}

        </Typography>
        <form
          className={classes.form}
          name="was_payinbundle_payment"
          method="post"
          onSubmit={e => {
            e.preventDefault();
          }}
          noValidate="novalidate"
        >
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                checked={giftaid === 1}
                value={giftaid}
                control={<Checkbox color="primary" />}
                label={text.Giftaid.permission}
                labelPlacement="end"
                onChange={e => {
                  updateGiftaid(e.target.checked === true ? 1 : 0);
                }}
              />
            </FormGroup>
          </FormControl>
          <ContinueButton
            id="continue"
            aria-label="Continue"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={e => onSubmit(e)}
          >
            {text.General.next}
          </ContinueButton>
        </form>
        <Typography component="p" variant="body2" gutterBottom>
          { text.Giftaid.legal }
        </Typography>
        <Typography component="p">{text.General.thanks_again}</Typography>
        <Button
          className={classes.back}
          variant="contained"
          id="js-back-details"
          role="button"
          onClick={e => {
            goBack(e);
          }}
          aria-label="Back to Giftaid claim"
        >
          {text.General.back}
        </Button>
      </div>
    </>
  );
};

export default connect(
  props => props,
  dispatch => bindActionCreators({ ...formActions }, dispatch),
)(DonationForm);
