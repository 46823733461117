import {
  createTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import ukraineFont from '../assets/fonts/ukraine/PublicSans-Light.ttf';
import titleImage from '../assets/ukraine.png';

const ukraineSansFont = {
  fontFamily: 'Public Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Public Sans'),
    url(${ukraineFont}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export default responsiveFontSizes(createTheme({
  name: 'ukraineTheme',
  body: {
    fontSize: '16px',
  },
  typography: {
    fontFamily: 'Public Sans, sans-serif',
  },
  button: {
    background: '#ffd700',
    backgroundHover: '#0057b8',
    color: '#000',
    colorHover: '#fff',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [ukraineSansFont],
      },
    },
    MuiLink: {
      root: {
        color: '#005ab4',
      },
    },
  },
  images: {
    titleImage: {
      src: titleImage,
      alt: 'RTE Ukraine Appeal',
    },
  },
}));
