/* eslint-disable */
import React from 'react';

import RTEHeader from '../../rte/header.html';

const weatherMobile = `
<a data-ati-tracking='{"campaign":"header","generalPlacement":"weather","creation":"top", "variant":"0", "format":"nav", "url":"Weather", "detailedPlacement":"Weather"}' class="weather-widget" href="/weather/">
<span class="warning"></span>
<span class="link">Weather</span>
<span class="icon"></span>
<span class="temperature"></span>
</a>
`;

const filteredHtml = RTEHeader.replace(new RegExp(weatherMobile, 'g'), '');

const RteHeader = () => {
  return <div dangerouslySetInnerHTML={{ __html: filteredHtml }} />;
};

export default RteHeader;
