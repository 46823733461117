import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Link, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import AmountInput from './AmountInput';
import Moneybuys from './Moneybuys';
import * as formActions from '../../Action/form';
import { PAYMENT_STATUS } from '../../Reducer/form';

import PaypalButton from '../PaypalButton';
import PaymentButtons from '../PaymentForm/PaymentButtons';

const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE || false;
const PAYPAL_BACKUP_MODE = process.env.REACT_APP_PAYPAL_BACKUP_MODE || false;

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: '0 0 2rem',
    maxWidth: '100%',
  },
  title: {
    margin: '1rem 4rem',
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 0',
    },
  },
  subTitle: {
    fontWeight: 'bold',
  },
  form: {
    margin: '1rem 0 1rem 0',
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxWidth: '550px',
    width: '100%',
    margin: 'auto',
  },
  communityImage: {
    width: '100%',
    maxWidth: '400px',
    margin: 'auto',
  },
}));

const MainText = ({ text }) => {
  const theme = useTheme();

  if (theme.name === 'toyshowTheme') {
    return (
      <>
        Your generosity will help charities which provide essential support,
        healthcare, well-being, play and creativity. All donations help to
        change children&apos;s lives for good.
      </>
    );
  }
  return (
    <>
      { text.DonationForm.intro }
    </>
  );
};

const SubText = ({ text }) => {
  const theme = useTheme();

  if (theme.name === 'toyshowTheme') {
    return (
      <>
        Funds donated through RTÉ Toy Show Appeal will go to support a
        range of children’s charities around Ireland and will be
        distributed by Community Foundation Ireland (Registered Charity
        Number (RCN): 20044886). More details available
        {' '}
        <Link
          color="primary"
          href="https://www.rte.ie/eile/toy-show-appeal/"
          target="_blank"
          rel="noopener"
        >
          here
        </Link>
        {' '}
        and Community Foundation Ireland
        {' '}
        <Link
          color="primary"
          href="https://www.communityfoundation.ie"
          target="_blank"
          rel="noopener"
        >
          website
        </Link>
        .
      </>
    );
  }

  return (
    <>
      { text.DonationForm.subtext }
      {' '}
      <Link
        color="primary"
        href="https://www.communityfoundation.ie/"
        target="_blank"
        rel="noopener"
      >
        {text.DonationForm.website}
      </Link>
    </>
  );
};

const DonateButton = withStyles(theme => ({
  root: {
    color: theme.button.color,
    fontSize: '1.4rem',
    padding: '10px',
    backgroundColor: theme.button.background,
    boxShadow: '2px 2px 0px #a13134',
    transform: 'translate(0, 0)',
    '&:focus, &:hover, &.selected': {
      backgroundColor: theme.button.backgroundHover,
      boxShadow: 'none',
      transform: 'translate(1px, 1px)',
    },
    width: '100%',
    textTransform: 'inherit',
    height: '64px',
  },
}))(Button);

const DonationForm = ({
  application: { text },
  form: { amount, step, paymentStatus },
  updateStep,
  resetFormState,
}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  if (step !== 0) {
    updateStep(0);
  }

  if (paymentStatus === PAYMENT_STATUS.COMPLETE) {
    resetFormState();
  }

  const paymentButtonError = typeof location.state !== 'undefined' && typeof location.state.paymentButtonError !== 'undefined'
    ? location.state.paymentButtonError
    : null;

  const onSubmit = async event => {
    event.preventDefault();

    if (amount >= 250) {
      updateStep(1);
      history.push('/giftaid');
    } else {
      updateStep(2);
      history.push('/address');
    }
  };

  return (
    <div className={classes.wrapper}>
      {MAINTENANCE_MODE ? (
        <Typography variant="h5" className={classes.title} component="h2">
          The payment platform is undergoing temporary maintenance,
          please try again later.
        </Typography>
      ) : (
        <>
          { paymentButtonError !== null && (
            <div
              className="error"
              style={{
                fontSize: '1.4em',
                textAlign: 'center',
                padding: '1em',
                width: '100%',
                fontWeight: 800,
                background: 'red',
                color: '#fff',
                marginTop: '1em',
              }}
            >
              { paymentButtonError }
            </div>
          )}
          <Typography variant="h5" className={classes.title} component="h2">
            <MainText text={text} />
          </Typography>
          <Typography
            className={classes.subTitle}
            variant="h4"
            component="h2"
            align="center"
          >
            {text.DonationForm.amount}
          </Typography>
          <form
            name="was_payinbundle_payment"
            onSubmit={e => {
              onSubmit(e);
            }}
            method="post"
            noValidate="novalidate"
            className={classes.form}
          >
            <div className={classes.buttons}>
              <Moneybuys />
              <AmountInput />
              {PAYPAL_BACKUP_MODE ? (
                <PaypalButton />
              ) : (
                /* eslint-disable react/jsx-fragments */
                <React.Fragment>
                  <DonateButton
                    size="large"
                    id="was_payinbundle_payment_amount_submit"
                    type="submit"
                    name="was_payinbundle_payment[submit]"
                    className="btn form__next cta"
                    disabled={amount < 2 || amount > 25000 || amount === ''}
                    variant="contained"
                  >
                    {amount !== '' ? `${text.General.donate} € ${amount} ${text.General.by_card}` : `${text.General.donate} ${text.General.by_card}`}
                  </DonateButton>
                  {
                    (typeof process.env.REACT_APP_PAYMENT_BUTTONS_DISABLED === 'undefined' || process.env.REACT_APP_PAYMENT_BUTTONS_DISABLED !== 'yes') && (
                      <PaymentButtons disabled={amount < 1 || amount > 25000 || amount === ''} />
                    )
                  }
                </React.Fragment>
              )}
            </div>
            <Typography
              variant="body1"
              className={classes.title}
              component="h2"
            >
              <SubText text={text} />
            </Typography>
          </form>
        </>
      )}
    </div>
  );
};

export default connect(
  props => props,
  dispatch => bindActionCreators({ ...formActions }, dispatch),
)(DonationForm);
