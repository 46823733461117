import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { useForm, Controller } from 'react-hook-form';
import isEmpty from 'lodash.isempty';

import * as formActions from '../../Action/form';
import countries from '../../Config/countries.json';

const ContinueButton = withStyles(theme => ({
  root: {
    color: theme.button.color,
    fontSize: '1.6rem',
    padding: theme.spacing(2),
    backgroundColor: theme.button.background,
    boxShadow: '2px 2px 0px #a13134',
    transform: 'translate(0, 0)',
    '&:focus, &:hover, &.selected': {
      backgroundColor: theme.button.backgroundHover,
      boxShadow: 'none',
      transform: 'translate(1px, 1px)',
    },
    width: '100%',
    textTransform: 'inherit',
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  back: {
    textTransform: 'capitalize',
    marginTop: '1rem',
  },
  title: {
    fontWeight: 'bold',
    margin: '0 4rem 1rem',
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 1rem',
    },
  },
  form: {
    maxWidth: '500px',
    width: '100%',
    margin: '2rem auto 4rem',
  },
  textField: {
    marginTop: '1em',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const AddressForm = ({
  address,
  application: { text },
  form: { amount, step },
  updateStep,
  updateDetails,
}) => {
  const [country, setCountry] = React.useState('IE');

  const history = useHistory();
  const classes = useStyles();

  const {
    register, errors, handleSubmit, control,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: address.firstName,
      lastName: address.lastName,
      email: address.email,
      address1: address.address1,
      address2: address.address2,
      county: address.county,
      town: address.town,
      country: address.country,
    },
  });

  if (step < 2) {
    history.push({ pathname: '/giftaid' });
  }

  /**
   * Go back to previous page
   * @param event
   */
  const goBack = () => {
    if (amount >= 250) {
      updateStep(1);
      history.push({ pathname: '/giftaid' });
    } else {
      updateStep(0);
      history.push({ pathname: '/' });
    }
  };

  const handleChange = (event) => {
    setCountry(event.target.value);
  };

  /**
   * On submission event
   * @param event
   */
  const onSubmit = data => {
    if (isEmpty(errors)) {
      updateDetails(data);
      updateStep(3);

      return history.push({ pathname: '/payment' });
    }

    return null;
  };

  return (
    <>
      <form
        noValidate
        className={classes.form}
        onSubmit={handleSubmit(data => onSubmit(data))}
      >
        <Typography
          className={classes.title}
          variant="h4"
          component="h2"
          align="center"
        >
          { text.AddressForm.your_details }
        </Typography>
        <TextField
          label={text.AddressForm.firstname}
          fullWidth
          variant="outlined"
          type="text"
          name="firstName"
          className={classes.textField}
          error={!!errors.firstName}
          inputRef={register({ required: true })}
        />
        <TextField
          label={text.AddressForm.lastname}
          fullWidth
          variant="outlined"
          type="text"
          name="lastName"
          className={classes.textField}
          error={!!errors.lastName}
          inputRef={register({ required: true })}
        />
        <TextField
          label={text.AddressForm.email}
          fullWidth
          variant="outlined"
          type="text"
          name="email"
          className={classes.textField}
          error={!!errors.email}
          inputRef={register({
            required: true,
            pattern:
              /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />
        <TextField
          label={text.AddressForm.address_1}
          fullWidth
          variant="outlined"
          type="text"
          name="address1"
          className={classes.textField}
          error={!!errors.address1}
          inputRef={register({ required: true })}
        />
        <TextField
          label={text.AddressForm.address_2}
          fullWidth
          variant="outlined"
          type="text"
          name="address2"
          className={classes.textField}
          error={!!errors.address2}
          inputRef={register()}
        />
        <TextField
          label={text.AddressForm.town}
          fullWidth
          variant="outlined"
          type="text"
          name="town"
          className={classes.textField}
          error={!!errors.town}
          inputRef={register({ required: true })}
        />
        <TextField
          label={text.AddressForm.county}
          fullWidth
          variant="outlined"
          type="text"
          name="county"
          className={classes.textField}
          error={!!errors.county}
          inputRef={register({ required: true })}
        />
        <FormControl fullWidth variant="outlined" className={classes.textField}>
          <InputLabel id="country-label">{text.AddressForm.country}</InputLabel>
          <Controller
            as={(
              <Select fullWidth id="country" label={text.AddressForm.country}>
                <MenuItem value="">None</MenuItem>
                {countries.map(item => (
                  <MenuItem key={`${item.Code}`} value={`${item.Code}`}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            )}
            onChange={handleChange}
            name="country"
            rules={{ required: 'this is required' }}
            control={control}
            defaultValue="IE"
            value={country}
          />
        </FormControl>

        <ContinueButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {text.General.continue}
        </ContinueButton>

        <Button
          className={classes.back}
          variant="contained"
          id="js-back-details"
          role="button"
          onClick={e => {
            goBack(e);
          }}
          aria-label="Back to Donate"
        >
          {text.General.back}
        </Button>
      </form>
    </>
  );
};

export default connect(
  props => props,
  dispatch => bindActionCreators({ ...formActions }, dispatch),
)(AddressForm);
