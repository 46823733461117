/* eslint-disable react/no-unescaped-entities */
import React from 'react';

export default () => (
  <main role="main" className="bg--blue-gradient">
    <section className="terms-section">
      <h1>TERMS OF USE FOR RTÉ DOES COMIC RELIEF WEBPAGES</h1>

      <h2>WEBSITE</h2>
      <p>
        These terms apply to your use of RTÉ Does Comic Relief webpages on its
        website. Please note that the webpages are intended to be used by those
        who live in Ireland and who are 18 and over.
      </p>

      <h2>WHO ARE WE?</h2>
      <p>
        The Sites are operated by Community Foundation Ireland (“we” or
        “us”) for RTÉ Does Comic Relief (“RTÉ Does Comic Relief”), which is a
        joint initiative between RTÉ and Kite Entertainment, Comic Relief Ltd’s
        Irish licensees, to raise funds foe Community Foundation Ireland, a
        registered charity in the Republic of Ireland (Charity number
        CHY 13967; Charity Registration Number 20044886; Company Registration
        Number 338427; Registered Office 30 Merrion Square North, Dublin 2, D02 VE40; Company
        Limited by Guarantee).
      </p>
      <p>
        If you’d like to get in touch about RTÉ Does Comic Relief, please
        contact Community Foundation Ireland at
        covidresponse@foundation.ie.
      </p>

      <h2>OUR AGREEMENT</h2>
      <p>
        By using the Sites you accept and agree to comply with these terms. We
        may amend these terms at any time by updating the text of this page, so
        please check back regularly.
      </p>
      <p>
        In these terms, “Sites” means www.heehee.ie and any other webpage we may
        own or operate for RTÉ Does Comic Relief from time to time.
      </p>

      <h2>YOUR PRIVACY</h2>
      <p>
        These terms also refer to Community Foundation Ireland’s Privacy
        Statement, which also applies to your use of the Sites.
      </p>

      <h2>THE SITES</h2>
      <p>
        The Sites are operated by Community Foundation Ireland for RTÉ
        Does Comic Relief. They are made available free of charge, and we do not
        guarantee that the Sites, or any Content, will be secure, free from bugs
        or viruses, available or uninterrupted, or that the Content is accurate,
        complete or up to date. The Sites may be updated and changed from time
        to time. Availability of all or any part of the Sites may be suspended
        or withdrawn or restricted for business and operational reasons.
      </p>

      <h2>CONTENT</h2>
      <p>
        In these terms, "Content" shall mean all content on the Sites, including
        all information, graphics, trademarks, logos, branding, designs, text,
        photos, films, sounds, software and applications.
      </p>
      <p>
        The Content on the Sites is provided for general information and
        entertainment only. It is not intended to be advice on which you should
        rely.
      </p>
      <p>
        With the exception of logos or other branding of parties other than
        Community Foundation Ireland that appear on the Sites and/or Content
        (the intellectual property rights to which remain with the owners of
        such logos or other branding), the Sites and the Content, including all
        intellectual property rights therein, are owned by or licensed to
        Community Foundation Ireland. All rights in the Content are reserved
        to their respective owners, unless expressly stated otherwise. You may
        print, download and use such Content, but only for your personal use or
        to share with others in order to raise awareness about RTÉ Does Comic
        Relief. In doing so, you must acknowledge RTÉ Does Comic Relief,
        Community Foundation Ireland, RTÉ (or any other identified
        contributors) as the authors of the Content.
      </p>

      <h2>RESTRICTIONS</h2>
      <p>You must not:</p>

      <ul>
        <li>
          Use any Content for personal gain, for any commercial purpose, to
          advertise or sell any products or services, to promote a business, or
          in any way which damages RTÉ Does Comic Relief and/or Community
          Foundation for Ireland’s reputation, takes advantage of it, or
          suggests any form of association, approval or endorsement by RTÉ Does
          Comic Relief and/or Community Foundation Ireland;
        </li>
        <li>
          Modify any Content you print or download in any way; use any
          illustrations, photographs, video or audio sequences or any graphics
          on the Sites separately from any accompanying text;
        </li>
        <li>
          Use any Content or the Sites in any way that breaches applicable law
          or regulation;
        </li>
        <li>
          Frame or use framing techniques to enclose any trademark, logo or
          other proprietary information (including images, text, page layout, or
          form) on the Sites without express written consent;
        </li>
        <li>
          Copy, transmit, store or distribute any part of the Sites or any
          Content, unless we have expressly indicated that you may do so;
        </li>
        <li>
          Transmit any data, send or upload any material that contains viruses,
          Trojan horses, worms, spyware or any other harmful programs or similar
          computer code designed to adversely affect the operation of any
          computer software or hardware;
        </li>
        <li>
          Interfere with or attempt to gain unauthorised access to the Sites,
          the server on which the Sites are stored or any server, computer or
          database connected to the Sites; or
        </li>
        <li>Collect or use any personal details from the Sites.</li>
      </ul>

      <p>
        You may use the Sites as permitted by these terms. Your right to use the
        Sites will terminate automatically without notice if you breach or do
        not comply with any of these terms. You must not use the Sites for any
        illegal purpose. We may also terminate your right to use any Sites at
        any time at our sole discretion.
      </p>
      <p>
        Unless explicitly permitted under applicable mandatory law, you must
        not, and you will not encourage, assist or authorise any other person to
        copy, modify, reverse engineer, decompile or disassemble, or otherwise
        tamper with, the Sites, whether in whole or in part, or create any
        derivative works from or of the Sites.
      </p>
      <p>
        You warrant that you will comply with the above provisions, and you will
        be liable to us and indemnify us for any breach of that warranty. This
        means that you will be responsible for any loss or damage RTÉ Does Comic
        Relief and/or Community Foundation Ireland suffer as a result of
        your breach of this warranty.
      </p>
      <p>
        In addition, if you fail to comply with these provisions, your right to
        use the Sites will end immediately and you will be required, at our
        option, to return or destroy any copies of the material you have made.
        We may report any such breach of these provisions to the relevant
        authorities and we will co-operate with those authorities by disclosing
        your identity to them. Any action that we take is without prejudice to
        any of our accrued rights.
      </p>

      <h2>CONTRIBUTIONS</h2>
      <p>
        In these terms, "Contribution(s)" means anything that you send to us,
        including any photographs, video or audio clips.
      </p>
      <p>
        Any Contributions you send us will be considered non-confidential and
        non-proprietary. You grant to us the following rights to use those
        Contributions:
      </p>

      <ul>
        <li>
          A perpetual, royalty-free, worldwide, non-exclusive right to use,
          reproduce, edit, distribute, prepare derivative works of, display, and
          broadcast the Contributions in any media, for any purpose and to
          sub-licence any Contributions. You consent to the use of your first
          name and town in connection with the use of your Contribution, and the
          promotion of your Contribution on the Sites;
        </li>
        <li>
          You grant to third parties (being RTÉ, the partners, broadcasters and
          suppliers of Community Foundation Ireland) a perpetual,
          royalty-free, worldwide, non-exclusive right to use, display and
          broadcast the Contributions in connection with the use of the Sites,
          and to promote the Sites and RTÉ Does Comic Relief, and in accordance
          with the functionality of the Sites; and
        </li>
        <li>
          You waive all ‘moral rights’ in the materials (‘moral rights’ are
          described in the Copyright and Related Rights Act 2000 (as amended))
          to allow the above uses.
        </li>
      </ul>

      <p>
        You must have all the necessary rights to submit your Contributions.
        This includes having the permission from everyone in a picture or video
        (for those aged under 18, permission must be from their parent or legal
        guardian). Contributions must not be defamatory, discriminatory,
        indecent or abusive, political, offensive, objectionable or otherwise
        inappropriate, and must not infringe any person’s intellectual property
        or privacy rights.
      </p>
      <p>
        You warrant that any such Contribution complies with these standards,
        and you will be liable to us and indemnify us for any breach of that
        warranty. This means that you will be responsible for any loss or damage
        RTÉ Does Comic Relief and/or Community Foundation Ireland suffer
        as a result of your breach of warranty.
      </p>
      <p>
        We have the right to disclose your identity to any third party who is
        claiming that any Contributions you make constitute a violation of their
        intellectual property rights, or of their right to privacy, or otherwise
        as we may deem to be appropriate.
      </p>
      <p>
        If you do not wish to grant us these rights, please do not upload, send
        or otherwise provide your Contribution to the Sites or to us. We may
        require you to sign a release form in respect of your Contribution.
      </p>
      <p>
        To the fullest extent permitted by law, we expressly disclaim all
        liability in connection with any Contributions made by users of the
        Sites wherever it may appear.
      </p>

      <h2>INTELLECTUAL PROPERTY CLAIMS</h2>
      <p>
        We respect the intellectual property of others. If you believe that your
        intellectual property rights have been used in a way that gives rise to
        concerns of infringement, please contact us at details provided above.
      </p>

      <h2>LIMITATION OF LIABILITY</h2>
      <p>
        PLEASE READ CAREFULLY. We (or our respective group companies (if
        applicable), officers, trustees, employees and agents) shall not be
        liable or responsible for any claim, loss, damage, costs and expenses of
        any nature (including without limitation for any direct, indirect,
        incidental, special, punitive, or consequential damages whether
        resulting from breach of contract, negligence or any other cause of
        action) arising in connection with the Sites or your use of them
        (including, without limitation, for any viruses, bugs, trojan horses or
        other contamination resulting from use of the Sites, for any
        interruption or delays to the use of the Sites or for any errors,
        omissions or inaccuracies in any Content). Nothing in these terms of use
        shall exclude or limit any liability for death or personal injury
        arising from negligence, our fraud or fraudulent misrepresentation, or
        for any other liability which cannot be excluded or limited by law.
      </p>
      <p>
        You agree that you are solely responsible for your use of the Sites, and
        that to the fullest extent permitted at law, neither we nor our
        officers, trustees, employees nor agents make any representations nor do
        we give any warranties, express or implied, concerning the Sites and the
        Content. In particular, we do not warrant or represent that the Sites or
        the Content is virus-free.
      </p>

      <h2>THIRD PARTY WEBSITES</h2>
      <p>
        The Sites may contain links to websites operated by parties other than
        us. Such links are provided for your convenience only and should not be
        interpreted as approval by us of those linked websites or information on
        them. We are is not responsible for the content of any third party
        websites linked to or from the Sites.
      </p>

      <h2>GENERAL PROVISIONS</h2>
      <p>
        If any provision of these terms of use is deemed to be invalid by a
        court of competent jurisdiction, this shall not affect the validity of
        the remaining provisions, which shall remain in full force and effect.
        Any failure or delay by us in asserting any of its rights or of any
        provision of these terms of use shall not constitute a waiver of such
        right or provision.
      </p>

      <h2>GOVERNING LAW AND JURISDICTION</h2>
      <p>
        These terms shall be governed by and construed in accordance with Irish
        Law. You agree that the courts of Ireland will have exclusive
        jurisdiction.
      </p>

      <h2>DONATIONS</h2>
      <p>
        If you donate to Community Foundation Ireland, please understand
        that, under Charity law, we are not permitted to offer any refunds of
        your donation except in very limited circumstances. Please contact us if
        you experience any issues with this or wish to discuss.
        {' '}
      </p>
      <p>
        If you are donating €250 or more, your donation may be eligible for a
        tax refund which can be donated to Community Foundation Ireland.
        We may inform you of this option when you are making your donation.
      </p>
      <p>
        We are not an accounting, taxation or financial adviser, and you should
        not rely on information given on this site to determine the accounting,
        tax or financial consequences of making a donation towards RTÉ Does
        Comic Relief. You should consult your own adviser(s) about any
        accounting, taxation or financial consequences that may affect you.
      </p>

      <h2>GIVE WITH CONFIDENCE</h2>
      <p>
        Community Foundation Ireland is registered with the Charities
        Regulator (Registered Charity Number (RCN): 20044886) and committed to
        following the codes and guidelines published by the Charities Regulator.
      </p>
      <p>
        If you would like more information about the Charities Regulator, or
        wish to refer a fundraising complaint to them, please visit the website
        (
        <a href="https://www.charitiesregulator.ie/en">
          www.charitiesregulator.ie
        </a>
        ).
      </p>
    </section>
  </main>
);
