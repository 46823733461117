export const FormActions = {
  RESET_FORM_STATE: 'RESET_FORM_STATE',
  RESET_ADDRESS_STATE: 'RESET_ADDRESS_STATE',
  UPDATE_ADDRESS_STATE: 'UPDATE_ADDRESS_STATE',
  UPDATE_AMOUNT: 'UPDATE_AMOUNT',
  UPDATE_GIFTAID: 'UPDATE_GIFTAID',
  INTERACTION_STATUS: 'INTERACTION_STATUS',
  UPDATE_PAYMENT_STATUS: 'UPDATE_PAYMENT_STATUS',
  UPDATE_STEP: 'UPDATE_STEP',
  UPDATE_TRANSACTION_ID: 'UPDATE_TRANSACTION_ID',
  UPDATE_DEFAULT_EMAIL: 'UPDATE_DEFAULT_EMAIL',
};

export const resetFormState = () => ({
  type: FormActions.RESET_FORM_STATE,
});

export const updateDetails = data => ({
  type: FormActions.UPDATE_ADDRESS_STATE,
  address: {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    address1: data.address1,
    address2: data.address2,
    county: data.county,
    town: data.town,
    country: data.country,
    agent: data.agent,
  },
});

export const resetAddressState = () => ({
  type: FormActions.RESET_ADDRESS_STATE,
});

export const updateAmount = amount => ({
  type: FormActions.UPDATE_AMOUNT,
  amount,
});

export const updateGiftaid = giftaid => ({
  type: FormActions.UPDATE_GIFTAID,
  giftaid,
});

export const updateInteractionStatus = interacted => ({
  type: FormActions.INTERACTION_STATUS,
  interacted,
});

export const updatePaymentStatus = paymentStatus => ({
  type: FormActions.UPDATE_PAYMENT_STATUS,
  paymentStatus,
});

export const updateStep = step => ({
  type: FormActions.UPDATE_STEP,
  step,
});

export const updateDefaultEmail = email => ({
  type: FormActions.UPDATE_DEFAULT_EMAIL,
  email,
});

// eslint-disable-next-line camelcase
export const updateTransactionID = transaction_id => ({
  type: FormActions.UPDATE_TRANSACTION_ID,
  // eslint-disable-next-line camelcase
  transaction_id,
});
