import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '1rem',
  },
}));

const Sorry = ({
  application: { text },
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        variant="h4"
        className={classes.title}
        component="h2"
        align="center"
      >
        {text.General.sorry}
      </Typography>
      <Typography component="p" variant="subtitle1" gutterBottom>
        {text.Sorry.line_1}
      </Typography>
      <Typography component="subtitle2">
        {text.Sorry.line_2}
      </Typography>
    </>
  );
};

export default connect(
  props => props,
  dispatch => bindActionCreators({ }, dispatch),
)(Sorry);
