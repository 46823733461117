/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import * as Sentry from '@sentry/react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as formActions from '../../../Action/form';
import { PAYMENT_STATUS } from '../../../Reducer/form';
import Config from '../../../Config';

const useStyles = makeStyles(theme => ({
  form: {
    margin: theme.spacing(0, 'auto'),
  },
  spinner: {
    width: '100px',
    textAlign: 'center',
    margin: '2rem auto',
  },
}));

const fieldStyle = {
  marginTop: '1rem',
};

const inputStyle = {
  border: '1px solid #c4c4c4',
  borderRadius: '4px',
  padding: '18.5px 14px',
  marginTop: '0.5rem',
};

const errorStyle = {
  display: 'block',
  marginTop: '0.25rem',
  color: '#f44336',
};

const createOptions = () => ({
  style: {
    base: {
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#c23d4b',
    },
  },
});

const fixAgent = (agent) => agent.replace('/agent/', '');

const DonateButton = withStyles(theme => ({
  root: {
    color: theme.button.color,
    fontSize: '1.6rem',
    padding: theme.spacing(2),
    backgroundColor: theme.button.background,
    boxShadow: '2px 2px 0px #a13134',
    transform: 'translate(0, 0)',
    '&:focus, &:hover, &.selected': {
      backgroundColor: theme.button.backgroundHover,
      boxShadow: 'none',
      transform: 'translate(1px, 1px)',
    },
    width: '100%',
    textTransform: 'inherit',
    marginTop: '1.5rem',
  },
}))(Button);

const StripeElements = ({
  application: { text },
  form,
  address,
  updatePaymentStatus,
  updateTransactionID,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const stripe = useStripe();
  const elements = useElements();

  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [tokenError, setTokenError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    formValid: false,
  });

  /**
   * On changes to stripe fields
   * @param changeObject
   */
  const onFieldChange = changeObject => {
    const temporaryValidation = {
      cardNumber: validation.cardNumber,
      cardExpiry: validation.cardExpiry,
      cardCvc: validation.cardCvc,
    };
    temporaryValidation[changeObject.elementType] = changeObject.complete;

    setValidation({
      ...validation,
      [changeObject.elementType]: changeObject.complete,
      formValid:
        temporaryValidation.cardNumber === true
        && temporaryValidation.cardExpiry === true
        && temporaryValidation.cardCvc === true,
    });
  };

  const handlePayment = async paymentMethod => {
    let executeResponse = await Axios({
      method: 'post',
      url: Config.api.stripe,
      data: {
        payment_method_id: paymentMethod.paymentMethod.id,
        amount: Math.floor(form.amount * 100),
        receipt_email: address.email,
        giftaid: form.giftaid,
        source: address.agent ? fixAgent(address.agent) : null,
        token,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: () => true,
    });

    if (executeResponse.status !== 201) {
      setShowErrors(true);
      setLoading(false);
      setRefreshReCaptcha(r => !r);

      if (typeof executeResponse.data !== 'undefined') {
        Sentry.captureException(new Error(executeResponse.data.message));
        return setTokenError(executeResponse.data.message);
      }

      Sentry.captureException(new Error(executeResponse.message));
      return setTokenError(executeResponse.message);
    }

    if (executeResponse.data.requires_action === true) {
      const cardAction = await stripe.handleCardAction(
        executeResponse.data.payment_intent_client_secret,
      );

      if (cardAction.error && cardAction.error.code) {
        switch (cardAction.error.code) {
          case 'payment_intent_authentication_failure':
            setShowErrors(true);
            setLoading(false);
            Sentry.captureException(
              new Error('Unable to authenticate the card'),
            );
            return setTokenError(text.CardDetails.unable_to_authenticate);

          default:
            Sentry.captureException(
              new Error(
                `${text.CardDetails.card_failed_with_code} ${cardAction.error.code}`,
              ),
            );
            return history.push({ pathname: '/sorry' });
        }
      }

      executeResponse = await Axios({
        method: 'post',
        url: Config.api.stripe,
        data: {
          payment_intent_id: cardAction.paymentIntent.id,
        },
        headers: {
          'Content-Type': 'application/json',
        },
        validateStatus: () => true,
      });

      if (executeResponse.status !== 201) {
        setShowErrors(true);
        setLoading(false);
        setRefreshReCaptcha(r => !r);

        if (typeof executeResponse.data !== 'undefined') {
          Sentry.captureException(new Error(executeResponse.data.message));
          return setTokenError(executeResponse.data.message);
        }

        Sentry.captureException(new Error(executeResponse.message));
        return setTokenError(executeResponse.message);
      }
    }

    updateTransactionID(executeResponse.data.transaction_id);
    updatePaymentStatus(PAYMENT_STATUS.COMPLETE);

    return history.push({ pathname: '/success' });
  };

  /**
   * On payment form submission
   * @param event
   * @return {Promise<void>}
   */
  const handleSubmit = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setLoading(true);

    if (validation.formValid !== true) {
      setLoading(false);
      return setShowErrors(true);
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const tokenResponse = await stripe.createToken(cardNumberElement);

    if (tokenResponse.error) {
      setShowErrors(true);
      setLoading(false);
      setRefreshReCaptcha(r => !r);
      return setTokenError(tokenResponse.error.message);
    }

    // Create the Stripe payment method
    const paymentMethod = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: `${address.firstName} ${address.lastName}`,
        email: address.email,
        address: {
          line1: address.address1,
          line2: address.address2,
          state: address.county,
          city: address.town,
          country: address.country,
        },
      },
    });

    if (paymentMethod.error) {
      setShowErrors(true);
      setLoading(false);
      setRefreshReCaptcha(r => !r);
      return setTokenError(paymentMethod.error.message);
    }

    return handlePayment(paymentMethod);
  };

  const onVerify = useCallback((rToken) => {
    setToken(rToken);
  }, []);

  return (
    <>
      {loading && (
        <div className={classes.spinner}>
          <CircularProgress color="secondary" />
        </div>
      )}

      {
        typeof process.env.REACT_APP_RECAPTCHA_SITEKEY !== 'undefined' && (
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
          />
        )
      }

      <div style={{ display: loading === true ? 'none' : 'block' }}>
        <div className={classes.form}>
          {tokenError !== null ? (
            <div className="error" style={{ color: 'red' }}>
              {tokenError}
            </div>
          ) : (
            ''
          )}
          <div style={fieldStyle}>
            <label htmlFor="card-number-element">
              {text.CardDetails.card_number}
              <div style={inputStyle}>
                <CardNumberElement
                  onChange={onFieldChange}
                  {...createOptions()}
                />
              </div>
              {validation.cardNumber === false && showErrors === true && (
                <span style={errorStyle}>{text.CardDetails.card_number_invalid}</span>
              )}
            </label>
          </div>
          <div style={fieldStyle}>
            <label htmlFor="expiration-date-element">
              {text.CardDetails.expiration_date}
              <div style={inputStyle}>
                <CardExpiryElement
                  onChange={onFieldChange}
                  {...createOptions()}
                />
              </div>
              {validation.cardExpiry === false && showErrors === true && (
                <span style={errorStyle}>
                  {text.CardDetails.card_expiration_invalid}
                </span>
              )}
            </label>
          </div>
          <div style={fieldStyle}>
            <label htmlFor="card-cvc-element">
              {text.CardDetails.cvc}
              <div style={inputStyle}>
                <CardCvcElement onChange={onFieldChange} {...createOptions()} />
              </div>
              {validation.cardCvc === false && showErrors === true && (
                <span style={errorStyle}>
                  {text.CardDetails.card_cvc_invalid}
                </span>
              )}
            </label>
          </div>
          <DonateButton
            disabled={!validation.formValid}
            size="large"
            variant="contained"
            id="was_payinbundle_payment_amount_submit"
            type="submit"
            name="was_payinbundle_payment[submit]"
            onClick={() => handleSubmit()}
          >
            {text.CardDetails.complete_donation}
          </DonateButton>
        </div>
      </div>
    </>
  );
};

export default connect(
  props => props,
  dispatch => bindActionCreators({ ...formActions }, dispatch),
)(StripeElements);
