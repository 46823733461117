import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useForm, Controller } from 'react-hook-form';
import isEmpty from 'lodash.isempty';

import AmountInput from '../DonationForm/AmountInput';
import Moneybuys from '../DonationForm/Moneybuys';
import * as formActions from '../../Action/form';
import { PAYMENT_STATUS } from '../../Reducer/form';

import countries from '../../Config/countries.json';

const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE || false;

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: '0 0 2rem',
    maxWidth: '100%',
  },
  title: {
    margin: '2rem 4rem 1rem',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: '2rem 0 1rem',
    },
  },
  subTitle: {
    fontWeight: 'bold',
    margin: '2rem auto 0',
  },
  form: {
    margin: '1rem 0 1rem 0',
  },
  textField: {
    marginTop: '1em',
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxWidth: '550px',
    width: '100%',
    margin: 'auto',
  },
  communityImage: {
    width: '100%',
    maxWidth: '400px',
    margin: 'auto',
  },
}));

const DonateButton = withStyles(theme => ({
  root: {
    color: theme.button.color,
    fontSize: '1.6rem',
    marginTop: '1rem',
    padding: theme.spacing(2),
    backgroundColor: theme.button.background,
    boxShadow: '2px 2px 0px #a13134',
    transform: 'translate(0, 0)',
    '&:focus, &:hover, &.selected': {
      backgroundColor: theme.button.backgroundHover,
      boxShadow: 'none',
      transform: 'translate(1px, 1px)',
    },
    width: '100%',
    textTransform: 'inherit',
  },
}))(Button);

const Agents = ({
  address,
  form: { paymentStatus, amount, step },
  updateStep,
  updateDefaultEmail,
  resetFormState,
  updateDetails,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { username } = useParams();
  const isAgent = true;

  const {
    register, errors, handleSubmit, control,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: address.firstName,
      lastName: address.lastName,
      email: address.email,
      address1: address.address1,
      address2: address.address2,
      county: address.county,
      town: address.town,
      country: address.country,
      agent: address.agent,
    },
  });

  if (step !== 3) {
    updateStep(3);
  }

  if (paymentStatus === PAYMENT_STATUS.COMPLETE) {
    resetFormState();
  }

  const onSubmit = data => {
    if (isEmpty(errors)) {
      updateDetails(data);
      if (data.email === '') {
        updateDefaultEmail('receipts@weareserverless.com');
      }
      updateStep(3);

      return history.push({ pathname: amount >= 250 ? '/giftaid' : '/payment' });
    }

    return null;
  };

  return (
    <div className={classes.wrapper}>
      {MAINTENANCE_MODE ? (
        <Typography variant="h5" className={classes.title} component="h2">
          RTÉ Toy Show Appeal is undergoing temporary payment maintenance,
          please try again later - thank you
        </Typography>
      ) : (
        <>
          <Typography
            className={classes.subTitle}
            variant="h4"
            component="h2"
            align="center"
          >
            Hello,
            {' '}
            {username}
            .
          </Typography>
          <form
            name="was_payinbundle_payment"
            onSubmit={handleSubmit(data => onSubmit(data))}
            method="post"
            noValidate="novalidate"
            className={classes.form}
          >
            <input type="hidden" name="agent" value={isAgent} />
            <div className={classes.buttons}>
              <TextField
                hiddenLabel
                type="hidden"
                name="agent"
                error={!!errors.firstName}
                inputRef={register({ required: true })}
                defaultValue={`/agent/${username}`}
              />
              <Typography
                className={classes.title}
                variant="h4"
                component="h4"
                align="center"
              >
                Customer Details
              </Typography>
              <TextField
                label="First Name"
                fullWidth
                variant="outlined"
                type="text"
                name="firstName"
                className={classes.textField}
                error={!!errors.firstName}
                inputRef={register({ required: true })}
              />
              <TextField
                label="Last Name"
                fullWidth
                variant="outlined"
                type="text"
                name="lastName"
                className={classes.textField}
                error={!!errors.lastName}
                inputRef={register({ required: true })}
              />
              <Moneybuys />
              <AmountInput />

              <TextField
                label="Email"
                fullWidth
                variant="outlined"
                type="text"
                name="email"
                className={classes.textField}
                error={!!errors.email}
                inputRef={register({
                  pattern:
                    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              <TextField
                label="Address line 1"
                fullWidth
                variant="outlined"
                type="text"
                name="address1"
                className={classes.textField}
                error={!!errors.address1}
                inputRef={register({ required: true })}
              />
              <TextField
                label="Town / City"
                fullWidth
                variant="outlined"
                type="text"
                name="town"
                className={classes.textField}
                error={!!errors.town}
                inputRef={register({ required: true })}
              />

              <FormControl fullWidth variant="outlined" className={classes.textField}>
                <InputLabel id="country-label">Country</InputLabel>
                <Controller
                  as={(
                    <Select fullWidth id="country" label="Country">
                      <MenuItem value="">None</MenuItem>
                      {countries.map(item => (
                        <MenuItem key={`${item.Code}`} value={`${item.Code}`}>
                          {item.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  name="country"
                  rules={{ required: 'this is required' }}
                  control={control}
                  defaultValue="IE"
                />
              </FormControl>

              <DonateButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={amount < 1 || amount > 25000 || amount === ''}
              >
                Continue
              </DonateButton>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default connect(
  props => props,
  dispatch => bindActionCreators({ ...formActions }, dispatch),
)(Agents);
