import { FormActions } from '../Action/form';

const defaultState = {
  firstName: '',
  lastName: '',
  email: '',
  address1: '',
  address2: '',
  county: '',
  town: '',
  country: 'IE',
  agent: '',
};

/**
 * Address Section Reducer
 * @param state
 * @param action
 * @return {*}
 */
export default function address(state = defaultState, action) {
  switch (action.type) {
    case FormActions.RESET_ADDRESS_STATE:
      return defaultState;
    case FormActions.UPDATE_DEFAULT_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case FormActions.UPDATE_ADDRESS_STATE:
      return {
        ...state,
        firstName: action.address.firstName,
        lastName: action.address.lastName,
        email: action.address.email,
        address1: action.address.address1,
        address2: action.address.address2,
        county: action.address.county,
        town: action.address.town,
        country: action.address.country,
        agent: action.address.agent,
      };
    default:
      return state;
  }
}
