import React from 'react';
import { useTheme } from '@material-ui/core';
import Helmet from 'react-helmet';

const TAGS = {
  toyshowTheme: {
    name: 'RTE.ie',
    domain: 'RTE.ie',
    title: 'RTÉ Toy Show Appeal | Charity | Donate | Late Late toy Show',
    description: 'RTÉ news brings you the latest Irish news, world news, international news and up to the minute reports on breaking Irish news stories and news from around the world. Watch and listen to Irish news stories on our video and audio streams.',
  },
  ukraineTheme: {
    name: 'Ukraine Appeal',
    domain: 'RTE.ie',
    title: 'Appeal donations | Charity | Donate',
    description: 'Your donation will help charities provide essential support to those fleeing the war in Ukraine.',
  },
};

function Head() {
  const theme = useTheme();
  const { name } = theme;
  const tags = TAGS[name];

  return (
    <Helmet>
      <title>{tags.title}</title>
      <meta property="og:title" content={tags.title} />
      <meta property="og:site_name" content={tags.name} />
      <meta name="twitter:title" content={tags.title} />
      <meta name="twitter:domain" content={tags.domain} />
      <meta name="twitter:description" content={tags.description} />
      <meta name="description" content={tags.description} />
    </Helmet>
  );
}

export default Head;
