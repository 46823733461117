import { FormActions } from '../Action/form';

export const PAYMENT_STATUS = {
  COMPLETE: 'COMPLETE',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
};

const defaultState = {
  amount: '',
  interacted: false,
  giftaid: 0,
  paymentStatus: PAYMENT_STATUS.PENDING,
  step: 0,
  source: null, // Agent ID if applicable
  transaction_id: null,
};

/**
 * Application Reducer
 * @param state
 * @param action
 * @return {*}
 */
export default function application(state = defaultState, action) {
  switch (action.type) {
    case FormActions.RESET_FORM_STATE:
      return defaultState;
    case FormActions.UPDATE_AMOUNT:
      return {
        ...state,
        amount: action.amount,
      };
    case FormActions.UPDATE_GIFTAID:
      return {
        ...state,
        giftaid: action.giftaid,
      };
    case FormActions.INTERACTION_STATUS:
      return {
        ...state,
        interacted: action.interacted,
      };
    case FormActions.UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        paymentStatus: action.paymentStatus,
      };
    case FormActions.UPDATE_STEP:
      return {
        ...state,
        step: action.step,
      };
    case FormActions.UPDATE_TRANSACTION_ID:
      return {
        ...state,
        transaction_id: action.transaction_id,
      };
    default:
      return state;
  }
}
