export const LOAD = 'load';
export const SET_LANGUAGE = 'language';
export const SET_TEXT = 'text';

export function triggerLoad() {
  return (dispatch) => dispatch({
    type: LOAD,
    payload: {},
  });
}

export function setLanguage(language) {
  return (dispatch) => dispatch({
    type: SET_LANGUAGE,
    payload: language,
  });
}

export function setText(text) {
  return (dispatch) => dispatch({
    type: SET_TEXT,
    payload: text,
  });
}
