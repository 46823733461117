import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import * as formActions from '../../../Action/form';
import Config from '../../../Config';

const ColorButton = withStyles(theme => ({
  root: {
    width: 'calc(33.3% - 0.5rem)',
    height: '80px',
    marginTop: '0.5rem',
    color: theme.button.color,
    padding: theme.spacing(2),
    fontSize: '1.5rem',
    backgroundColor: theme.button.background,
    boxShadow: '2px 2px 0px #0073a9',
    transform: 'translate(0, 0)',
    '&:focus, &:hover': {
      backgroundColor: theme.button.backgroundHover,
      boxShadow: 'none',
      transform: 'translate(1px, 1px)',
      color: theme.button.colorHover,
    },
    '&.selected': {
      backgroundColor: theme.button.backgroundHover,
      boxShadow: 'none',
      transform: 'translate(1px, 1px)',
      color: theme.button.colorHover,
      fontWeight: 'bold',
    },
  },
}))(Button);

const Moneybuys = ({ form: { amount }, updateAmount }) => (
  <>
    {Object.keys(Config.moneybuys).map(moneyBuyAmount => (
      <ColorButton
        key={moneyBuyAmount}
        className={
          parseFloat(amount).toFixed(2)
          === parseFloat(moneyBuyAmount).toFixed(2)
            ? 'select-amount-btn selected'
            : 'select-amount-btn'
        }
        onClick={event => {
          event.preventDefault();
          updateAmount(moneyBuyAmount);
        }}
        aria-label={`£${moneyBuyAmount} could pay for ${Config.moneybuys[moneyBuyAmount]}`}
      >
        {`€ ${moneyBuyAmount}`}
      </ColorButton>
    ))}
  </>
);

export default connect(
  props => props,
  dispatch => bindActionCreators({ ...formActions }, dispatch),
)(Moneybuys);
