import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StripeElements from './StripeElements';

import * as formActions from '../../Action/form';
import { PAYMENT_STATUS } from '../../Reducer/form';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    margin: '2rem 0 1rem',
  },
  back: {
    marginTop: '2rem',
    alignSelf: 'flex-start',
  },
  form: {
    maxWidth: '500px',
    width: '100%',
    margin: '0 auto 4rem',
  },
}));

/**
 * Payment Form
 * @param application
 * @param form
 * @param updateStep
 * @param address
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentForm = ({
  application,
  form,
  updateStep,
  address,
}) => {
  const history = useHistory();
  const classes = useStyles();

  if (form.step < 3) {
    history.push({ pathname: '/address' });
  }

  if (form.paymentStatus === PAYMENT_STATUS.COMPLETE) {
    history.push({ pathname: '/success' });
  }

  /**
   * Go back to previous page
   * @param event
   */
  const goBack = () => {
    updateStep(2);
    history.push({ pathname: address.agent ? address.agent : '/address' });
  };

  return (
    <>
      <div className={classes.form}>
        <form
          name="was_payinbundle_payment"
          onSubmit={e => {
            e.preventDefault();
          }}
          method="post"
          noValidate="novalidate"
          className="has-validation-callback"
        >
          <Typography
            variant="h4"
            className={classes.title}
            component="h2"
            gutterBottom
            align="center"
          >
            {application.text.CardDetails.title}
          </Typography>
          <StripeElements />
        </form>
        <Button
          className={classes.back}
          variant="contained"
          id="js-back-details"
          role="button"
          onClick={e => {
            goBack(e);
          }}
          aria-label="Back to Giftaid claim"
        >
          {application.text.General.back}
        </Button>
      </div>
    </>
  );
};

export default connect(
  props => props,
  dispatch => bindActionCreators({ ...formActions }, dispatch),
)(PaymentForm);
