import { takeEvery, put } from 'redux-saga/effects';
import { FormActions } from '../../Action/form';

function* updateAmount() {
  yield put({ type: FormActions.INTERACTION_STATUS, interacted: true });
}

/**
 * Register application sagas
 */
export default function* sagas() {
  yield takeEvery(FormActions.UPDATE_AMOUNT, updateAmount);
}
