export default {
  imagery: {
    side: {
      desktop: '/images/bg-image-1.jpg',
      mobile: '/images/bg-image-1.jpg',
      alt: 'Cart Image',
    },
  },
  text: {
    title:
      'RTÉ Does Comic Relief is raising money in support of Community Foundation Ireland to help those most in need as a result of Covid 19.',
    subTitle:
      'Your donation will help fund projects providing essential supports and assistance to vulnerable communities who are impacted by this crisis.',
  },
  moneybuys: {
    10: 'Will support vital community services to re-open to help people',
    25: 'Will support communities all over Ireland',
    50: 'Will support the most vulnerable among us',
    100: 'Will support the most vulnerable among us',
  },
  payments: {
    stripe: {
      public: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    },
    paypal: {
      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || 'test',
    },
  },
  api: {
    stripe: process.env.REACT_APP_API_URL || `${process.env.PUBLIC_URL}/api/stripe`,
  },
};
