import React, { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';

const HeightPusher = () => {
  const location = useLocation();

  useEffect(() => {
    const formHeight = document.getElementById('donation-form') !== null
      ? document.getElementById('donation-form').clientHeight
      : '1000';

    window.parent.postMessage(JSON.stringify({
      // Added another 100px to account for possible recaptcha
      iframe_height: formHeight * 1 + 100,
    }));
  }, [location]);

  return (
    <></>
  );
};

export default withRouter(HeightPusher);
