import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useHistory } from 'react-router-dom';
import * as formActions from '../../Action/form';
import Config from '../../Config';

const paypalOptions = {
  'client-id': Config.payments.paypal.clientId,
  // TODO move to currency config
  currency: 'EUR',
  // intent: 'capture'
};

const PaypalButton = ({
  form: { amount },
  updateTransactionID,
}) => {
  const history = useHistory();

  const createOrder = (data, actions) => actions.order.create({
    purchase_units: [
      {
        amount: {
          value: amount,
        },
      },
    ],
  });

  const onApprove = (data) => {
    updateTransactionID(data.orderID);
    history.push({ pathname: '/success' });
  };

  return (
    <div style={{ width: '100%' }}>
      <PayPalScriptProvider options={paypalOptions}>
        <PayPalButtons
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
          forceReRender={[amount]}
          disabled={!parseInt(amount, 2)}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default connect(
  props => props,
  dispatch => bindActionCreators(
    { ...formActions },
    dispatch,
  ),
)(PaypalButton);
